import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    background: '#FFF',
    textAlign: 'center',
    paddingBottom: '40px',
  },
  blockGreen: {
    position: 'relative',
    width: 'calc(100% - 30px)',
    height: '100%',
    background: 'rgb(236, 251, 255) none repeat scroll 0% 0%',
    color: '#FFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    margin: '15px',
    padding: '25px 10px',
    overflow: 'hidden',
  },
  blockBlue: {
    position: 'relative',
    width: 'calc(100% - 30px)',
    height: '100%',
    background: 'rgb(244, 242, 252) none repeat scroll 0% 0%',
    color: '#FFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    margin: '15px',
    padding: '25px 10px',
    overflow: 'hidden',
  },
}))

export default function CompanyBlock() {
  const classes = useStyles()

  return (
    <div id='block-company' className={classes.root}>
      <Container maxWidth='lg'>
        <div className='pageHeader'>О Компании</div>
        <center>
          <div className='pageSubHeader'>С 2011 года мы производим влажные салфетки для российского рынка и ближнего зарубежья. В активе компании квалифицированный персонал,  все необходимые технологии, оборудование и сертификаты.</div>
        </center>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} md={6}>
            <div className={classes.blockGreen}>
              <h2>Производство влажных салфеток под собственными торговыми марками «Аквамарин» и «Акватория».</h2>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.blockBlue}>
              <h2>Производство влажных салфеток под торговой маркой заказчика и в соответствии с его требованиями.</h2>
            </div>
          </Grid>
        </Grid> 
      </Container>
    </div>
  )
}

