import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"

let theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0197fd',
    },
    secondary: {
      main: '#53a321',
    }
  },
  shape: {
    borderRadius: '0px',
  },
})

const app = (
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
)

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
