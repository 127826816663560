import React from 'react'
import './WelcomeBlock.css'

export default function WelcomeBlock(props) {
  return (
    <div id='block-company' className='welcome-block'>
      <div className='welcome-block-logo' />
      <div className='welcome-block-img' />
      <div className='welcome-block-cont'>
        <div className='welcome-block-cont-head'>
          <h1>
            Производство влажных салфеток
          </h1>
          <h5>
            Под собственными торговыми марками «Аквамарин» и «Акватория»
          </h5>
        </div>
        <div className='welcome-block-cont-body'>
          <h2>
            Выберите то,что вам нужно или закажите салфетки со своим дизайном
          </h2>
        </div>
      </div>
      <div className='welcome-block-tail'>
        <h5>
          С 2011 года мы производим влажные салфетки для российского рынка и ближнего зарубежья.
        </h5>
      </div>
    </div>
  )
}
