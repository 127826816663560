import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import bg from '../images/logo.jpg'
import material from '../images/material.jpg'

const useStyles = makeStyles(theme => ({
  root: (props => ({
    position: 'relative',
    width: '100%',
    zIndex: '10',
    height: '100%',
    minHeight: '100vh',
    padding: '10px 0',
  })),
  bg: (props => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
//    backgroundImage: `url('https://sc04.alicdn.com/kf/Ha9315e5819bb4db3bab8709e774c9d2fG.jpg')`,
    backgroundImage: `url(${material})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    transform: 'translateZ(-3px)',
    opacity: '0.2',
  })),
  fg: (props => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#FFF',
  })),
  spanleis: {
    width: '350px',
    height: '350px',
    border: '2px solid #FFF',
    borderRadius: '50%',
//    backgroundImage: `url('https://medsklad-opt.ru/upload/iblock/656/6568f5f280b85786d08c2c7b952c37a1.jpg')`,
    backgroundImage: `url(${material})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}))

const MaterialsBlock = () => {
  const classes = useStyles()
  return (
    <div id='block-materials' className={classes.root}>
      <div className={classes.bg} />
      <div className={classes.fg}>
        <h2>
          Материалы
        </h2>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <center>
              <h4>Материал, используемый для производства влажных салфеток — спанлейс.</h4>
            </center>
          </Grid>
          <Grid item xs={12} md={6}>
            <center>
              <div className={classes.spanleis} />
            </center>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box m={2}>
              <center>
                <h4>
                  Полученный мягкий безворсовый материал обладает высокой впитываемостью и наиболее подходит для использования при изготовлении влажных салфеток и во многих областях косметологии, а так же медицины.
                </h4>
              </center>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <center>
            </center>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default MaterialsBlock
