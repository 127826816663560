import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  bg: (props=>({
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${props.image})`,
    backgroundColor: '#FFF',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  })),
}))

export default function ProductItem(props) {
  const image = props.image
  const brand = props.brand
  const descr = props.descr
  const size = props.size
  const barcodePack = props.barcodePack
  const barcodeBox = props.barcodeBox
  const classes = useStyles({ image })
//    <div className={classes.root}>
//      <div className={classes.bg} />
//    </div>
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className={classes.bg} />
        </div>
        <div className="flip-card-back">
          <h3 style={{color:'#FFF',margin:'5px 0'}}>{brand}</h3> 
          <h4 style={{color:'#FFF',margin:'5px 0'}}>{descr}, {size} шт.</h4>
          <p>Штрих-код упаковка</p> 
          <p>{barcodePack}</p>
          <p>Штрих-код коробка</p>
          <p>{barcodeBox}</p>
        </div>
      </div>
    </div>
  )
}
