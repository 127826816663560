import React from 'react'
import TailBlock from './TailBlock'
import { MyMap } from './MyMap'
import './ContactBlock.css'

export default function ContactBlock() {
  return (
    <div id='block-contacts' className='contact-block'>
      <div
        style={{
          position:'absolute',
          width:'100%',
          height:'100%',
          top: '0',
          margin:'0',
          zIndex:'2'
        }}
      >
        <MyMap />
      </div>
      <TailBlock />
    </div>
  )
}
