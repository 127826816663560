import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    background: 'rgb(236, 251, 255) none repeat scroll 0% 0%',
    textAlign: 'center',
    paddingBottom: '40px',
  },
}))

export default function NewsBlock() {
  const classes = useStyles()

  return (
    <div id='block-news' className={classes.root}>
      <Container maxWidth='lg'>
        <div className='pageHeader'>Новости</div>
      </Container>
    </div>
  )
}

