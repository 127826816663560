import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import logo from '../images/logo.png'
import MenuIcon from '@material-ui/icons/Menu'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import logoTextWhite from '../images/logoTextWhite.png'

import useHasMounted from '../hooks/useHasMounted'
import useScrollPosition from '../hooks/useScrollPosition'
import useWindowSize from '../hooks/useWindowSize'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
})

const useStyles = makeStyles((theme) => ({
  root: (props=>({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '80px',
    zIndex: '600',
  })),
  rootBg: (props=>({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: props.contained ? 'none' : '#FFF',
    opacity: '0.95',
    borderBottom: props.contained ? '0px' : '1px solid #edeff6',
    zIndex: '650',
  })),
  rootFg: {
    position: 'relative',
    zIndex: '700',
    opacity: '1',
  },
  menu: (props=>({
    position: 'relative',
    height: '80px',
    display: props.mobile ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  })),
  menuMobile: (props=>({
    position: 'relative',
    height: '80px',
    display: props.mobile ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
  })),
  logo: (props=>({
    position: 'relative',
    width: '60px',
    height: '60px',
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  })),
  menuIcon: (props=>({
    color: '#121B4D',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
  })),
  logoWhite: {
    position: 'relative',
    width: '100%',
    height: '180px',
    marginBottom: '15px',
    backgroundImage: `url(${logoTextWhite})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    zIndex: '51',
  },
  mobileCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#121B4D',
    width: '100%',
    height: '100vh',
  },
  menuMobileBtn: {
    color: '#FFF',
    fontFamily: 'Oswald',
    fontSize: '2rem',
    fontWeight: '300',
    letterSpacing: '2px',
    padding: '10px 0',
  }
}))

export default function Top() {
  const [openDialog, setOpenDialog] = React.useState(false)
  const scrollPosition = useScrollPosition()
  const {size, height} = useWindowSize()

  let contained = scrollPosition < 150 ? true : false
  let mobile = size === 'xs' || size === 'sm' ? true : false

  const classes = useStyles({ contained, mobile })

  return (
    <>
    <div className={classes.root}>
      <div className={classes.rootBg} />
      <div className={classes.rootFg}>
        <Container maxWidth='lg'>
          <div className={classes.menu}>
            <div className={classes.logo} />
            <a href='#block-company'>
              <h5>
                О Компании
              </h5>
            </a>
            <a href='#block-products'>
              <h5>
                Продукция
              </h5>
            </a>
            <a href='#block-materials'>
              <h5>
                Материалы
              </h5>
            </a>
            <a href='#block-contacts'>
              <h5>
                Контакты
              </h5>
            </a>
            <a>
              <h4 style={{color:'#41B619'}}>
                +7 923 349 77 23
              </h4>
            </a> 
          </div>
          <div className={classes.menuMobile}>
            <MenuIcon className={classes.menuIcon} onClick={()=>setOpenDialog(true)}/>
            <a href='tel:89233497723'>
              <h4 style={{color:'#41B619'}}>
                +7 923 349 77 23
              </h4>
            </a> 
          </div>
        </Container>
      </div>
    </div>
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      fullScreen
    >
      <div className={classes.mobileCont}>
        <a href='#block-welcome' onClick={()=>setOpenDialog(false)} className={classes.logoWhite} />
        <a href='#block-company' onClick={()=>setOpenDialog(false)} className={classes.menuMobileBtn}>О Компании</a>
        <a href='#block-products' onClick={()=>setOpenDialog(false)} className={classes.menuMobileBtn}>Продукция</a>
        <a href='#block-materials' onClick={()=>setOpenDialog(false)} className={classes.menuMobileBtn}>Материалы</a>
        <a href='#block-contacts' onClick={()=>setOpenDialog(false)} className={classes.menuMobileBtn}>Контакты</a>
      </div>
    </Dialog>
    </>
  )
}
