import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import ProductsList from './ProductsList'
import Type1 from '../images/type-1.jpg'

import useWindowSize from '../hooks/useWindowSize'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  type: (props=>({
    position: 'relative',
    padding: '15px',
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: props.mobile ? '#FFF' : 'none',
  })),
  cont: (props=>({
    padding: props.mobile ? '0' : '35px',
    textAlign: props.mobile ? 'center' : 'left'
  })),
  type1: (props=>({
    width: '100%',
    height: props.mobile ? '300px' : '500px',
    backgroundImage: `url('https://image.jimcdn.com/app/cms/image/transf/none/path/sddaf7b2faf16457f/image/i39611ad53a1c238b/version/1410299488/image.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',    
  })),
  type2: (props=>({
    width: '100%',
    height: props.mobile ? '300px' : '500px',
    backgroundImage: `url('https://www.vladtime.ru/uploads/posts/2018-06/1528747569_infants_smile_glance_493480_1920x1200.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',    
  })),
  type3: (props=>({
    width: '100%',
    height: props.mobile ? '300px' : '500px',
    backgroundImage: `url('https://poisktv.ru/wp-content/uploads/2022/01/schastlivaya_semya_18_04130139-1140x641.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',    
  })),
  type4: (props=>({
    width: '100%',
    height: props.mobile ? '300px' : '500px',
    backgroundImage: `url('https://riverviewmed.files.wordpress.com/2014/02/doctor-with-hands-in-heart-shape.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',    
  })),
  typeSizeBlock: (props=>({
    position: 'relative',
    width: 'calc(100% - 30px)',
    height: '240px',
    background: '#FFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    margin: '15px',
    padding: '10px',
    overflow: 'hidden',    
  })),
}))

export default function ProductsBlock(props) {
  const {size, height} = useWindowSize()
  let mobile = size === 'xs' || size === 'sm' ? true : false
  const classes = useStyles({mobile})

  return (
    <div className={classes.root}>

      <Container id='block-products' maxWidth='xl'>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
              <center>
                <div className='pageHeader'>
                  <h2>
                    Наша продукция
                  </h2>
                </div>
                <div className='pageSubHeader'>
                  <h5>
                    Мы производим влажные салфетки с наполнением от 15 до 72 штук в каждой упаковке.
                  </h5>
                </div>
              </center>
          </Grid>
          <ProductsList />
          <Grid container justifyContent='center' alignItems='center'>
            <Box m={10}>
              <button onClick={()=>props.showDialog()} className="btn btn-white btn-animate">Заказать</button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <center>
              <h2>
                Виды влажных салфеток
              </h2>
              <h5>
                Охват всех наиболее важных сфер применения влажных салфеток.
              </h5>

          <Grid container className={classes.type} style={{left:'0'}}>
            <Grid item xs={12} md={6} className={classes.type1}/>
            <Grid item xs={12} md={6} className={classes.cont}>
              <h3>
                Освежающие
              </h3>
              <h4>
                Удобный карманный формат упаковки позволяет всегда держать салфетки под рукой. Освежающие влажные салфетки эффективно очищают, освежают и увлажняют кожу.
              </h4>
            </Grid>
          </Grid>

          {mobile ? <Grid container className={classes.type}>
            <Grid item xs={12} md={6} className={classes.type2}/>
            <Grid item xs={12} md={6} className={classes.cont}>
              <h3>
                Детские
              </h3>
              <h4>
                Детские влажные салфетки специально созданы для бережного ухода за чувствительной детской кожей от 3-х лет. Они заботливо очищают, увлажняют и освежают нежную кожу ребенка. Не содержат спирта.
              </h4>
            </Grid>
          </Grid>:
          <Grid container className={classes.type}>
            <Grid item xs={12} md={6} className={classes.cont}>
              <h3>
                Детские
              </h3>
              <h4>
                Детские влажные салфетки специально созданы для бережного ухода за чувствительной детской кожей от 3-х лет. Они заботливо очищают, увлажняют и освежают нежную кожу ребенка. Не содержат спирта.
              </h4>
            </Grid>
            <Grid item xs={12} md={6} className={classes.type2}/>
          </Grid>
          }

          <Grid container className={classes.type}>
            <Grid item xs={12} md={6} className={classes.type3}/>
            <Grid item xs={12} md={6} className={classes.cont}>
              <h3>
                Универсальные
              </h3>
              <h4>
                Универсальные влажные салфетки изготовлены из мягкого и нежного материала, обладают очищающим,  увлажняющим и освежающим действием.
              </h4>
            </Grid>
          </Grid>

          {mobile ? <Grid container className={classes.type}>
            <Grid item xs={12} md={6} className={classes.type4}/>
            <Grid item xs={12} md={6} className={classes.cont}>
              <h3>
                Антибактериальные
              </h3>
              <h4>
                Мягкие и нежные салфетки идеально подходят для взрослых и детей от 3-х лет. Влажные салфетки прекрасно очищают и тонизируют кожу рук и тела, оказывают дополнительный антибактериальный эффект
              </h4>
            </Grid>
          </Grid>:
          <Grid container className={classes.type}>
            <Grid item xs={12} md={6} className={classes.cont}>
              <h3>
                Антибактериальные
              </h3>
              <h4>
                Мягкие и нежные салфетки идеально подходят для взрослых и детей от 3-х лет. Влажные салфетки прекрасно очищают и тонизируют кожу рук и тела, оказывают дополнительный антибактериальный эффект.
              </h4>
            </Grid>
            <Grid item xs={12} md={6} className={classes.type4}/>
          </Grid>
          }
        </center>
      </Container>
    </div>
  )
}
