import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ProductItem from './ProductItem'

import p1 from '../images/product-1-1.jpg'	// Акватория, 15, освеж. Ромашка
import p2 from '../images/product-1-2.jpg'	// Акватория, 15, освеж. Морской бриз
import p3 from '../images/product-1-3.jpg'	// Акватория, 15, освеж. Персидский лайм 
import p4 from '../images/product-1-4.jpg'	// Акватория, 15, антибактериальные
import p5 from '../images/product-1-5.jpg'	// Акватория, 15, освеж. Алоэ вера
import p6 from '../images/product-1-6.jpg'	// Акватория, 15, освеж. Зеленый чай

import p8 from '../images/product-2-2.jpg'	// Акватория, 50, океанический лед
import p10 from '../images/product-2-4.jpg'	// Акватория, 50, антибактериальные
import p11 from '../images/product-2-5.jpg'	// Акватория, 50, алоэ вера

import p7 from '../images/product-2-1.jpg'	// Аквамарин, 72, унивесальные
import p9 from '../images/product-2-3.jpg'	// Аквамарин, 72, детские
import p12 from '../images/product-2-6.jpg'	// Аквамарин, 72, антибактериальные
import p14 from '../images/product-2-8.jpg'	// Аквамарин, 72, освеж. Алоэ вера - Зеленый чай

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
}))

export default function ProductsList() {
  const classes = useStyles()
  const [showItems, setShowItems] = React.useState(1)	// 1, 2, 3 or 4

  const items15 = (
    <div>
      <Grid container justifyContent='center'>
        <h3 style={{color:'#41B619'}}>«Акватория» 15 шт.</h3>
      </Grid>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p1}
            brand='Акватория'
            descr='Освежающие "Ромашка"'
            size='15'
            barcodePack='4603736595152'
            barcodeBox='4603736595329'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p2}
            brand='Акватория'
            descr='Освежающие "Морской бриз"'
            size='15'
            barcodePack='4603736595138'
            barcodeBox='4603736595381'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p3}
            brand='Акватория'
            descr='Освежающие "Персидский лайм"'
            size='15'
            barcodePack='4603736595800'
            barcodeBox='4603736595312'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p4}
            brand='Акватория'
            descr='Антибактериальные'
            size='15'
            barcodePack='4603736595145'
            barcodeBox='4603736595374'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p5}
            brand='Акватория'
            descr='Освежающие "Алоэ Вера"'
            size='15'
            barcodePack='4603736595176'
            barcodeBox='4603736595411'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p6}
            brand='Акватория'
            descr='Освежающие "Зеленый чай"'
            size='15'
            barcodePack='4603736595169'
            barcodeBox='4603736595404'
          />
        </Grid>
      </Grid>
    </div>
  )

  const items50 = (
    <div>
      <Grid container justifyContent='center'>
        <h3 style={{color:'#41B619'}}>«Акватория» 50 шт.</h3>
      </Grid>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p8}
            brand='Акватория'
            descr='Океанический лед'
            size='50'
            barcodePack='4603736595824'
            barcodeBox='4603736595343'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p10}
            brand='Акватория'
            descr='Антибактериальные'
            size='50'
            barcodePack='4603736595817'
            barcodeBox='4603736595336'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p11}
            brand='Акватория'
            descr='Алоэ Вера'
            size='50'
            barcodePack='4603736595831'
            barcodeBox='4603736595350'
          />
        </Grid>
      </Grid>
    </div>
  )

  const items72 = (
    <div>
      <Grid container justifyContent='center'>
        <h3>«Аквамарин» 72 шт.</h3>
      </Grid>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p7}
            brand='Аквамарин'
            descr='Универсальные'
            size='72'
            barcodePack='4603736595756'
            barcodeBox='4603736595749'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p9}
            brand='Аквамарин'
            descr='Детские'
            size='72'
            barcodePack='4603736595732'
            barcodeBox='4603736595725'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p12}
            brand='Аквамарин'
            descr='Антибактериальные'
            size='72'
            barcodePack='4603736595763'
            barcodeBox='4603736595770'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className='productsListItem'>
          <ProductItem
            image={p14}
            brand='Аквамарин'
            descr='Освежающие "Алоэ Вера - Зеленый чай"'
            size='72'
            barcodePack='4603736595787'
            barcodeBox='4603736595794'
          />
        </Grid>
      </Grid>
    </div>
  )

  const displayControl = (
    <div className='control'>
      <div
        onClick={()=>setShowItems(1)}
        className={showItems === 1 ? 'controlButtonActive' : 'controlButton'}        
      >
        <h5>
          Все
        </h5>
      </div>
      <div
        onClick={()=>setShowItems(2)}
        className={showItems === 2 ? 'controlButtonActive' : 'controlButton'}        
      >
        <h5>
          15 шт.
        </h5>
      </div>
      <div
        onClick={()=>setShowItems(3)}
        className={showItems === 3 ? 'controlButtonActive' : 'controlButton'}        
      >
        <h5>
          50 шт. 
        </h5>
      </div>
      <div
        onClick={()=>setShowItems(4)}
        className={showItems === 4 ? 'controlButtonActive' : 'controlButton'}        
      >
        <h5>
          72 шт. 
        </h5>
      </div>
    </div>
  )

  let display = (
    <div>
      {items15}
      {items50}
      {items72}
    </div>
  )

  if (showItems === 2) {
    display = items15
  }

  if (showItems === 3) {
    display = items50
  }

  if (showItems === 4) {
    display = items72
  }

  return (
    <>
      {displayControl}
      {display}
    </>
  )
}

