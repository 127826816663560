import React, { Component } from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import baloonPng from '../images/baloon.png'

class MyMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    let centerMap = [56.07223571730444, 92.95083708465565]
    let centerBaloon = [56.07423571730444, 92.95083708465565]

    return (
      <YMaps>
        <Map
          defaultState={{
            center: centerMap,
            zoom: 15,
            zoomControl: true,
            controls: [],
          }}
          style={{
            position:'absolute',
            width:'100%',
            height:'100%',
          }}
        >
          <Placemark
            geometry={centerBaloon}
            options={
              {
                iconLayout: 'default#image',
                iconImageHref: baloonPng,
                iconImageSize: [150, 150],
                iconImageOffset: [-80, -160]
              }
            }
          />
        </Map>
      </YMaps>
    )
  }
}

export { MyMap }
