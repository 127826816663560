import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: (props => ({
    position: 'relative',
    transition: 'all ease .45s',
    width: '100%',
    height: '400px',
  })),
  bg: (props => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: `url('https://apico-tour.ru/wp-content/uploads/2021/03/1.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    transform: 'translateZ(-3px)',
  })),
  fg: (props => ({
    paddingTop: '100px',
    paddingBottom: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
  }))  
}))

const ParallaxBlock = (props) => {
  const bg = props.bg
  const classes = useStyles({ bg })
  return (
    <div className={classes.root}>
      <div className={classes.bg} />
      <div className={classes.fg}>
      </div>
    </div>
  )
}

export default ParallaxBlock
