import React from 'react'
import Top from './components/Top'
import WelcomeBlock from './components/WelcomeBlock'
import ParallaxBlock from './components/ParallaxBlock'
import CompanyBlock from './components/CompanyBlock'
import ProductsBlock from './components/ProductsBlock'
import MaterialsBlock from './components/MaterialsBlock'
import NewsBlock from './components/NewsBlock'
import ContactBlock from './components/ContactBlock'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
      firstName: '',
      phone: '',
    }
    this.tgMsg = this.tgMsg.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  showDialog = () => {
    this.setState({ openDialog: true })
  }

  hideDialog = () => {
    this.setState({ openDialog: false })
  }

  firstNameChange = event => {
    this.setState({ firstName: event.target.value })
  }

  phoneChange = event => {
    this.setState({ phone: event.target.value })
  }

  afterSale = async () => {
    await this.setState({
      openDialog: false,
      firstName: '',
      phone: ''
    })
    alert('Заявка успешно отправлена! Менеджер скоро свяжется с вами.')
  }

  async tgMsg() {
    const firstName = this.state.firstName
    const phone = this.state.phone
    const body = { firstName: firstName, phone: phone }
    const options = {}
    options.method = 'POST'
    options.headers = {'Content-Type': 'application/json'}
    options.body = JSON.stringify(body) 
    try {
      const res = await fetch('https://aquamarin.space/tgApi/order', options)
      if (res) {
        const result = await res.json()
        if (result) {
          this.afterSale()
          return
        } else {
          alert ('Fetch returned bad JSON')
          return
        }
      } else {
        alert ('Fetch returned null')
        return
      }
    } catch (e) {
      console.log('Fetch error:', e)
      return
    }
  }

  render() {
    return (
      <div>
        <Top />
        <WelcomeBlock showDialog={this.showDialog} />
        <ProductsBlock showDialog={this.showDialog}/>
        <MaterialsBlock />
        <ContactBlock />

        <Dialog
          open={this.state.openDialog}
          TransitionComponent={Transition}
          onClose={this.hideDialog}
        >
          <DialogContent>
            <center>
            <h3 style={{margin:'0'}}>Обратная связь</h3>
            <h4 style={{margin:'0'}}>Наши сотрудники всегда рады Вам помочь</h4>
            </center>
            <Box my={2}>
              <input type='text' className='styled-input' placeholder='Как вас зовут' onChange={this.firstNameChange}/>
            </Box>
            <Box my={2}>
              <input type='number' className='styled-input' placeholder='Введите ваш номер телефона' onChange={this.phoneChange} />        
            </Box>
            <center>
              <Box m={3}>
                <button onClick={this.tgMsg} className="btn btn-white btn-animate">Отправить</button>
              </Box>
            </center>
          </DialogContent>
        </Dialog> 

      </div>
    )
  }
}

export default App
