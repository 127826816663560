import React from 'react'
import Grid from '@material-ui/core/Grid'
import ContactInfo from './ContactInfo'
import './TailBlock.css'

export default function TailBlock() {
  return (
    <div className='tail-block'>
      <Grid container justifyContent='center' alignItems="center" >
        <Grid item xs={12} sm={6}>
          <center>
            <ContactInfo />
          </center>
        </Grid>
        <Grid item xs={12} sm={6}>
          <center>
            <div className='tail-block-link'>
              <a href='/docs/declaration.png'>
                <h5>
                  Декларация о соответствии
                </h5>
              </a>
            </div>
            <div className='tail-block-link'>
              <a href='/docs/certificate.pdf'>
                <h5>
                  Свидетельство о регистрации
                </h5>
              </a>
            </div>
          </center>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' alignItems="center" className='tail-block-c'>
        <h5 style={{margin:'0',padding:'10px 0'}}>© 2022 Все права защищены.</h5>
      </Grid>
      <div className='tail-block-logo' />
    </div>
  )
}
