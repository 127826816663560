import React from 'react'
import './ContactInfo.css'

export default function ContactInfo() {
  return (
    <div className='contact-info'>
      <div className='contact-info-cont'>
        <h6>660118, Россия Красноярский край г. Красноярск,</h6>
        <h6>ул. Полигонная, 11д, пом. 102</h6>
        <h6>ИНН/КПП 2465256023/246501001</h6>
        <h6>ОГРН 1112468037912</h6>
        <h6>mail@aquamarin.space</h6>
      </div>
    </div>
  )
}
